

Pace.on('done', function () {
    document.querySelector('.header').classList.remove('is-hidden');
    
    const windowWidht = $(window).width();
    if(windowWidht>1240){
        const timelineStart = anime.timeline();
        timelineStart.add({
            targets: ['.logo_an','.menu_an'],
            opacity: 1,
            duration: 1000,
            delay:1000
        }).add({
            targets: ['.line-hero-left'],
            translateX:[-1000, 0],
            duration: 2000,
            elasticity: 400,
            easing: 'easeOutQuart'
        }).add({
            targets: ['.line-hero-right'],
            translateX:[1000, 0],
            offset: '-=2000',
            duration: 2000,
            elasticity: 400,
            easing: 'easeOutQuart'
        }).add({
            targets: '.slogan-an',
            opacity:[0, 1],
            duration: 500,
            easing: 'easeOutExpo',
            offset: '-=500'
        })
        .add({
            targets: '.triforce-hero',
            opacity:[0, 0.3],
            scale:[0.1, 1],
            rotate: function() { return anime.random(-360, 360); },
            duration: 2000,
            elasticity: 100,
            offset: '-=2500'
        })
        .add({
            targets: '.toScroll-an',
            opacity:[0, 1],
            scale:[0.1, 1],
            duration: 1000,
            offset: '-=2000'})
        .add({
            targets: '.h1-desc',
            opacity:[0, 1],
            scale:[0.1, 1],
            duration: 1000,
            offset: '-=2000'
        }).add({
            targets: ['.phone-an'],
            translateX:[-1000, 0],
            opacity:[0, 1],
            duration: 2000,
            elasticity: 400,
            offset: '-=1100'
        }).add({
            targets: ['.email-an'],
            translateX:[1000, 0],
            opacity:[0, 1],
            duration: 2000,
            elasticity: 400,
            offset: '-=2000',
            complete: function () {
                document.querySelector('body').classList.add('start');
            }
        }) 
    }else{
        document.querySelector('body').classList.add('start');
    }
    
    
   
})